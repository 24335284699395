/* src/index.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-datepicker/dist/react-datepicker.css';

/* Custom CTA Button */
.cta-button {
  @apply inline-block mt-2 px-6 py-3 bg-green-500 text-white rounded hover:bg-green-600 transition-colors;
}

/* Accordion Styles */
.accordion-button {
  @apply w-full text-left px-4 py-2 bg-gray-100 dark:bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500;
}

.accordion-content {
  @apply px-4 py-2 bg-white dark:bg-gray-800 rounded-md;
}

/* Social Icons */
.social-icons a {
  @apply text-gray-600 dark:text-gray-300 hover:text-green-600 dark:hover:text-green-400 transition-colors;
}

/* Dark Mode Overrides */
.dark .prose {
  @apply prose-dark;
}

.dark .cta-button {
  @apply bg-green-400 hover:bg-green-500;
}

/* Additional custom styles if needed */